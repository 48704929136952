import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();
const DCard = ({ name, title, img }) => {
  return (
    <div
      className='bg-white dark:bg-slate-700 dark:text-white shadow-md rounded-xl flex p-6  cursor-pointer 0md:gap-1 gap-2 2xl:h-[300px] group'
      data-aos='fade-up'
      data-aos-duration='2000'
    >
      <img
        src={img}
        alt='gamo'
        className='group-hover:scale-110 0md:block  md:hidden  0md: rounded-full md:rounded-t-3xl w-44 h-44  0md:w-20 0md:h-20  object-cover object-top   transition duration-200 ring-green-500 ring-2 group-hover:ring-blue-400 '
        loading='lazy'
      />

      <div className='lg:w-[40%]  0md:hidden mr-4'>
        <img
          src={img}
          alt='gamo'
          className='group-hover:scale-110  rounded-t-3xl w-40 h-40  0md:w-28 0md:h-28  object-cover object-top   transition-all duration-200 ring-green-500 ring-2 group-hover:ring-blue-400'
          loading='lazy'
        />
      </div>
      <div className=' text-gray-500  px-4 lg:w-[60%] dark:text-gray-200'>
        <h1 className='mb-4 font-bold text-2xl 0md:text-xl'>{name}</h1>
        <p className='text-xl 0md:text-base 0md:font-medium'>{title}</p>
        <hr className='w-10 bg-yellow-400 p-[.8px] mt-3' />

        <div className='flex  md:space-x-2 mt-4 '>
          <Link to='#' className='pr-2'>
            <FaFacebookF className='bg-green-500 text-4xl p-2 rounded-full text-white group-hover:bg-blue-500 cursor-pointer ' />
          </Link>
          <Link to='#' className='pr-2'>
            <FaTwitter className='bg-green-500 text-4xl p-2 rounded-full text-white group-hover:bg-blue-500 cursor-pointer ' />
          </Link>
          <Link to='#' className='pr-2'>
            <FaInstagram className='bg-green-500 text-4xl p-2 rounded-full text-white group-hover:bg-blue-500 cursor-pointer ' />
          </Link>
          <Link to='#' className='pr-2'>
            <FaLinkedin className='bg-green-500 text-4xl p-2 rounded-full text-white group-hover:bg-blue-500 cursor-pointer ' />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DCard;
