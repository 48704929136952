import React, { useEffect, useState } from 'react';

import { BiMenuAltRight } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BsFillSunFill, BsFillMoonFill } from 'react-icons/bs';

const Nav = () => {
  const [toggle, setToggle] = useState(false);
  //className={`text-red-500 ${toggle ? 'block' : 'hidden'} font-extrabold`}

  const [theme, setTheme] = useState(localStorage ? localStorage.getItem('theme') : null);

  const Themetoggle = () => {
    theme === 'dark' ? setTheme('light') : setTheme('dark');
  };

  useEffect(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      localStorage.setItem('theme', 'dark');
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, []);
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else if (theme === 'light') {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    } else {
      localStorage.removeItem('theme');
    }
  }, [theme]);

  return (
    <header className='shadow-xl z-[100]  sticky top-0  bg-slate-50 dark:text-slate-50 dark:bg-slate-800'>
      <div>
        <nav className='flex justify-between items-center h-20'>
          <Link to='/' className='flex items-center'>
            <img src='gamo.png' alt='logo' className='object-contain w-32 h-24 block' />
            <span className='-ml-11 zmd:hidden text-3xl font-extrabold 0m:text-xs  text-blue-600 -mb-4 font-serif '>
              Gamo Hospital
            </span>
          </Link>

          <div className='flex space-x-3 0md:hidden px-5 py-2 '>
            {theme === 'dark' ? (
              <BsFillMoonFill
                className='cursor-pointer text-xl hover:text-blue-500'
                onClick={Themetoggle}
              />
            ) : (
              <BsFillSunFill
                className='cursor-pointer text-2xl hover:text-blue-500'
                onClick={Themetoggle}
              />
            )}

            {[
              ['Home', '/'],
              ['About', '/about'],
              ['Services', '/services'],
              ['Contact', '/contact'],
              ['Book an Appointment', '/appointment'],
            ].map(([title, url]) => (
              <Link
                to={url}
                key={title}
                className={`px-3 py-2  dark:text-slate-200 font-medium text-lg hover:underline  hover:font-semibold hover:text-gray-400 hover:underline-offset-8 hover:decoration-green-300 decoration-2 ${
                  title === 'Book an Appointment'
                    ? 'hover:bg-green-800 rounded-lg text-slate-200 bg-green-500 hover:no-underline '
                    : 'text-green-700'
                }`}
              >
                {title}
              </Link>
            ))}
          </div>
          <div className='md:hidden flex items-center space-x-3'>
            <Link
              to='/appointment'
              className='font-semibold   dark:bg-blue-900  rounded-md block bg-green-300 px-2 py-1 0sm:text-sm text-white'
            >
              Appointment
            </Link>
            <button
              onClick={() => (toggle ? setToggle(false) : setToggle(true))}
              className={`text-purple-500 ${
                toggle ? 'hidden' : 'block'
              } font-extrabold px-4 py-2 block`}
            >
              <BiMenuAltRight className='text-4xl text-blue-800' />
            </button>
          </div>
        </nav>
      </div>
      <div className='md:hidden text-center'>
        {toggle && (
          <div className=' animate-wiggle px-10 py-2  dark:bg-slate-800 bg-blue-400 absolute right-0 top-0 bottom-0 h-screen z-50  '>
            <div>
              <AiOutlineClose
                className='text-white text-3xl absolute top-3 right-4  font-extrabold'
                onClick={() => (toggle ? setToggle(false) : setToggle(true))}
              />

              <div className='mt-14'>
                {theme === 'dark' ? (
                  <BsFillMoonFill
                    className='cursor-pointer text-xl hover:text-blue-500'
                    onClick={Themetoggle}
                  />
                ) : (
                  <BsFillSunFill
                    className='cursor-pointer text-2xl hover:text-blue-500'
                    onClick={Themetoggle}
                  />
                )}
                {[
                  ['Home', '/'],
                  ['About', '/about'],
                  ['Services', '/services'],
                  ['Contact', '/contact'],
                ].map(([title, url]) => (
                  <Link
                    to={url}
                    key={title}
                    onClick={() => (toggle ? setToggle(false) : setToggle(true))}
                    className='rounded-lg px-3 py-2 text-white  text-left hover:bg-slate-400 font-semibold hover:text-slate-900 hover:font-semibold block'
                  >
                    {title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Nav;
