import React from 'react';
import DCard from '../DCard/DCard';

const Team = () => {
  return (
    <div>
      <h1 className='2xl:text-6xl text-4xl dark:text-gray-100 font-extrabold font-serif text-center p-2 mt-4 text-swatch_4'>
        Our Team
      </h1>
      <div className='gap-4 grid p-4 mt-4  lg:grid-cols-[1fr_1fr] 2xl:grid-cols-[1fr_1fr_1fr] justify-center'>
        <div>
          <DCard name='Dr. Aniefiok McCarthy ' title='Medical Director and CEO' img='doc.jpg' />
        </div>
        <div>
          <DCard
            name='Dr. (Mrs) Waribo Helen A.'
            title='Medical Laboratory Scientist'
            img='tl.png'
          />
        </div>
        <div>
          <DCard name='McCarthy Mfon Esq. CiArb' title='Legal Officer/HRM' img='barr.jpg' />
        </div>

        <div>
          <DCard name='Dr. (Mrs) Ngerebara Unyime M' title='Anesthesiologist' img='rsz_1ani.jpg' />
        </div>

        <div>
          <DCard name='Dr. (Mrs) Iwara Komommo' title='Obstetrics and Gynecology' img='t3.png' />
        </div>

        <div>
          <DCard name='Mrs Juliet Uchenna' title='Nurse' img='t2.png' />
        </div>
      </div>
    </div>
  );
};

export default Team;
