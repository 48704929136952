import React from 'react';
import Card from './Card';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonia = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <h1 className='text-4xl dark:text-slate-200 text-gray-500 font-serif font-extrabold text-center p-5'>
        Testimonials
      </h1>
      <div className='grid place-items-center'>
        <hr className='w-20  p-[.8px] bg-cyan-400' />
      </div>

      <div className='my-4 p-6 mb-10'>
        <Slider {...settings}>
          <Card
            name='Peculiar Chima'
            title='Event Planner'
            img='pecu.jpg'
            quote='The staff worked seamlessly together, ensuring that every aspect of my treatment was carefully coordinated and executed. With their compassionate approach and dedication to patient well-being, Gamo Hospital truly stands out as a beacon of excellence in healthcare. I wholeheartedly recommend Gamo Hospital to anyone in need of outstanding medical services. '
          />
          <Card
            name='Prince Uche'
            title='Backend Engineer'
            img='urch.jpg'
            quote="The medical team's expertise and dedication were evident throughout my entire stay, ensuring I received the best treatment possible. The state-of-the-art facilities and cutting-edge technology further added to the level of confidence I had in their capabilities. Thanks to Gamo Hospital, my road to recovery was smooth and swift."
          />
          <Card
            name='Morgan Uwalaka'
            title='Real Estate Agent'
            img='morgan.jpg'
            quote=' From the moment I entered the facility, the staff made me feel welcomed and valued. The doctors and nurses exhibited unmatched expertise, attentiveness, and compassion throughout my treatment journey. The state-of-the-art equipment and modern facilities at Gamo Hospital further enhanced my confidence in the quality of care provided.'
          />
          <Card
            name='Isabela Jimmy'
            title='Enterprenuer'
            img='ata.jpg'
            quote='I cannot express my gratitude enough for the exceptional care I received at Gamo Hospital. From the moment I walked through the doors, I felt reassured by the warm and welcoming staff who genuinely cared about my well-being.  I highly recommend Gamo Hospital to anyone seeking top-notch medical care and compassionate support during their time of need.'
          />
          <Card
            name='Blessing Chinedum'
            title='Enterprenuer'
            img='bb.jpg'
            quote='I recently had the privilege of receiving medical care at Gamo Hospital, and I am delighted to share my remarkable experience. From the moment I entered the facility, I was greeted with warmth and empathy by the staff. The doctors and nurses exhibited exceptional professionalism, displaying a deep understanding of my medical needs and offering comprehensive treatment options.  '
          />
        </Slider>
      </div>
    </div>
  );
};

export default Testimonia;
