import React, { useState } from 'react';

const Departments = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (e, id) => {
    setActiveTab(id);
  };
  return (
    <div>
      <h1 className='text-4xl text-gray-500 dark:text-slate-200 font-serif font-extrabold text-center p-5'>
        Departments
      </h1>
      <div className='grid place-items-center'>
        <hr className='w-20  p-[.8px] bg-cyan-400 mb-4' />
      </div>
      <p className='text-lg md:text-center dark:text-slate-400 text-gray-700 p-5 font-serif text-center'>
        Gamo Hospital is proud to offer a wide range of specialized departments that cater to
        diverse healthcare needs. The Pediatrics & Child Care unit stands as a beacon of hope for
        children and families, providing exceptional medical care in a child-friendly environment.
        The Accident and Emergency unit serves as a lifeline during emergencies, delivering urgent
        and efficient medical care with skilled physicians and state-of-the-art resources.
      </p>
      <div className='my-4 p-6 md:flex gap-x-4 '>
        <div>
          <ul className='box-border'>
            {[
              {
                id: 1,
                name: 'Cardiology',
                text: 'Led by a team of renowned cardiologists and equipped with cutting-edge technology',
              },
              {
                id: 2,
                name: 'Neurology',
                text: 'Led by a team of highly skilled and experienced neurologists,committed to research and innovation ',
              },
              {
                id: 3,
                name: 'Hepatology',
                text: 'Led by a team of accomplished hepatologists and supported  facilities',
              },
              {
                id: 4,
                name: 'Pediatrics',
                text: 'Led by team of highly skilled pediatricians and dedicated pediatric nurses.',
              },
            ].map((item, index) => (
              <li
                className={` lg:w-56 lg:h-32  mb-3 font-serif  px-3 ${
                  activeTab === item.id ? 'bg-cyan-50 dark:bg-cyan-50/30' : ''
                } border-l-4   cursor-pointer  ${
                  activeTab === item.id ? 'border-cyan-400' : 'hover:border-gray-400'
                }`}
                onClick={(e) => handleTabClick(e, item.id)}
                key={index}
              >
                <h4
                  className={`text-lg dark:text-cyan-500 box-border font-serif font-semibold text-gray-700  ${
                    activeTab === item.id ? 'text-cyan-400' : ''
                  }`}
                >
                  {item.name}
                </h4>
                <p className='text-base text-gray-600 dark:text-slate-400'>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <div>
            {[
              {
                id: 1,
                name: 'Cardiology',
                img: 'cardio.jpg',
                t2: "The Cardiology department at Gamo Hospital is dedicated to delivering comprehensive and advanced cardiac care to patients. With a team of highly skilled cardiologists and state-of-the-art facilities, they provide a wide range of diagnostic and treatment services for various cardiovascular conditions. From preventive screenings to interventional procedures, the cardiology department offers a holistic approach to managing heart health. With cutting-edge technology and a patient-centered approach, they strive to improve patient outcomes and enhance the overall well-being of individuals with heart-related issues. Whether it's managing chronic conditions or providing emergency cardiac care, Gamo Hospital's cardiology department is committed to delivering excellence in cardiac healthcare, ensuring that patients receive the highest level of specialized treatment and care.",
                t1: "Gamo Hospital's Cardiology Department is a cornerstone of excellence in heart care, dedicated to providing comprehensive and state-of-the-art cardiac services.",
              },
              {
                id: 2,
                img: 'neuro.jpg',
                t2: "Gamo Hospital's Neurology Department is a center of excellence for comprehensive neurological care, providing expert diagnosis, treatment, and management of a wide range of neurological disorders. Led by highly skilled neurologists, the department utilizes advanced diagnostic technologies and state-of-the-art facilities to deliver accurate and precise evaluations. From neurological examinations to specialized imaging studies, patients receive thorough assessments to guide personalized treatment plans. The department offers cutting-edge interventions for conditions such as stroke, epilepsy, Parkinson's disease, and multiple sclerosis, ensuring optimal outcomes and improved quality of life. With a strong focus on patient-centered care, the Neurology Department at Gamo Hospital is committed to delivering compassionate support and empowering patients to navigate their neurological conditions with confidence.",
                name: 'Neurology',
                t1: "Gamo Hospital's Neurology Department is a leading center of excellence, dedicated to providing cutting-edge care and comprehensive solutions for patients with neurological disorders",
              },
              {
                id: 3,
                img: 'hep.jpg',
                t2: "Gamo Hospital's Hepatology Department is a center of excellence dedicated to the diagnosis, treatment, and management of liver diseases. Led by a team of highly skilled hepatologists, the department offers comprehensive care for patients with various liver conditions, including hepatitis, liver cirrhosis, liver cancer, and other liver-related disorders. With state-of-the-art diagnostic tools and advanced treatment modalities, including liver transplantation, the department ensures accurate diagnosis, personalized treatment plans, and ongoing monitoring to optimize patient outcomes. The hepatologists collaborate closely with multidisciplinary teams, including surgeons, radiologists, and oncologists, to provide holistic care and tailor interventions to individual needs. Gamo Hospital's Hepatology Department is committed to improving liver health, enhancing quality of life, and making a positive impact in the lives of patients affected by liver diseases.",
                name: 'Hepatology',
                t1: "Gamo Hospital's Hepatology Department stands as a center of excellence for liver care, offering specialized and comprehensive services to patients with liver diseases.",
              },
              {
                id: 4,
                img: 'ped.jpg',
                t2: "Gamo Hospital's Pediatrics Department is a pillar of compassionate and specialized care for children, offering comprehensive healthcare services from infancy to adolescence. Led by a team of dedicated pediatricians, the department is committed to providing exceptional medical attention, promoting the well-being and development of every child. With child-friendly examination rooms, advanced diagnostic tools, and a nurturing environment, they ensure accurate diagnoses and effective treatment for a wide range of pediatric conditions. From routine check-ups to specialized care for complex diseases, the Pediatrics Department focuses on holistic healthcare, emphasizing preventive measures, immunizations, and parental education. Gamo Hospital's Pediatrics Department is a trusted resource for families, providing expert care that ensures the health and happiness of every child in their care.",
                name: 'Pediatrics',
                t1: "Gamo Hospital's Pediatrics Department is a trusted provider of specialized and compassionate care for children.",
              },
            ].map(
              (item, i) =>
                activeTab === item.id && (
                  <div className='font-serif' data-aos='zoom-in' key={i}>
                    <h3 className='text-2xl text-cyan-400 pb-4 font-semibold'>{item.name}</h3>
                    <p className='text-gray-600 text-lg mb-4 dark:text-slate-400'>{item.t1}</p>
                    <div className='lg:flex gap-x-4'>
                      <img
                        className='object-cover object-center w-[350px] 0md:w-full mb-4 dark:mix-blend-darken'
                        src={item.img}
                        alt=''
                        loading='lazy'
                      />
                      <p className=' text-gray-600 text-lg box-border dark:text-slate-400'>
                        {item.t2}
                      </p>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
