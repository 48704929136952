import React from 'react';

const ChooseCard = ({ para, title, Icon }) => {
  return (
    <div className='drop-shadow-lg dark:bg-cyan-800 dark:text-slate-300 bg-cyan-50 p-4 font-serif text-gray-500 rounded-xl'>
      {Icon && <Icon className='text-4xl text-swatch_6' />}
      <h4 className='text-2xl my-4 font-semibold '>{title}</h4>
      <p className='text-lg'>{para}</p>
    </div>
  );
};

export default ChooseCard;
