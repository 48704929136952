import React from 'react';
import ChooseCard from '../chooseCard/ChooseCard';
import { FaStethoscope, FaHospital } from 'react-icons/fa';
import { BiAccessibility, BiDonateBlood } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const ChooseUs = () => {
  return (
    <div>
      <div></div>
      <div className='md:flex gap-4 justify-between p-4 border-t-2 border-b-2 0md:space-y-4'>
        <div className=' md:w-1/2' data-aos='fade-up-right' data-aos-duration='2000'>
          <h1 className=' font-serif mb-4 text-blue-600 0md:text-3xl font-semibold text-4xl'>
            Why Choose <br /> Gamo Hospital
          </h1>
          <Link to='/about'>
            <button className='dark:bg-swatch_6 cursor-pointer px-4 py-2 bg-yellow-400 text-lg font-semibold font-serif text-white rounded-md hover:bg-swatch_6'>
              Learn More
            </button>
          </Link>
        </div>
        <div className='grid md:grid-cols-[1fr_1fr] gap-4 '>
          <div data-aos='fade-left' data-aos-duration='2000'>
            <ChooseCard
              Icon={FaStethoscope}
              title='More experience'
              para='The million patients we treat each year prepares us to treat the one who matters most — you.'
            />
          </div>
          <div data-aos='fade-left' data-aos-duration='2000'>
            <ChooseCard
              Icon={BiAccessibility}
              title='You come first'
              para="Treatment at Gamo Clinic is a truly human experience. You're cared for as a person first."
            />
          </div>
          <div data-aos='fade-right' data-aos-duration='2000'>
            <ChooseCard
              Icon={BiDonateBlood}
              title='The right answers'
              para='Count on our experts to deliver an accurate diagnosis and the right plan for you the first time.'
            />
          </div>
          <div data-aos='fade-right' data-aos-duration='2000'>
            <ChooseCard
              Icon={FaHospital}
              title='Innovation with impact'
              para='All of our patient care, education and research are driven to make discoveries that can help heal you.'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
