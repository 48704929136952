import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Card = ({ Icon, para, heading }) => {
  const { pathname } = useLocation();
  return (
    <div className='bg-white dark:bg-slate-700 dark:text-slate-300 p-4 text-center font-serif text-gray-600 space-y-4 hover:shadow-2xl cursor-pointer transition duration-200 hover:-translate-y-2'>
      <div className='grid place-items-center'>
        {Icon && (
          <Icon className='text-center text-7xl cursor-pointer text-white rounded-full p-2 ring-4 ring-gray-50 drop-shadow-2xl bg-cyan-300 shadow-2xl ' />
        )}
      </div>

      <h4 className='text-3xl'>{heading}</h4>
      <div className='grid place-items-center'>
        <hr className='w-20 p-[.8px] bg-cyan-400 ' />
      </div>

      <p className='text-lg '>{para}</p>

      {pathname === '/' && (
        <div>
          <Link to='/services' className='bg-cyan-300 px-4 py-2 rounded-md text-white text-lg'>
            {' '}
            learn more
          </Link>
        </div>
      )}
    </div>
  );
};

export default Card;
