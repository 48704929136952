import React from 'react';
import VideoPlayer from '../../components/video/VideoPlayer';

import DcountUp from '../../components/countUp/DcountUp';

import { FaAward } from 'react-icons/fa';
import { FcDepartment } from 'react-icons/fc';
import { ImLab } from 'react-icons/im';
import { BsFillPeopleFill } from 'react-icons/bs';

import { BiBraille, BiRupee, BiRocket, BiAnchor } from 'react-icons/bi';
import { FaAmbulance } from 'react-icons/fa';
import { GiMedicines } from 'react-icons/gi';

import ChooseUs from '../../components/chooseUs/ChooseUs';

import Values from '../../components/values/Values';
import Team from '../../components/team/Team';
import Testimonia from '../../components/testimonia/Testimonia';
import Gallery from '../../components/gallery/Gallery';
import Departments from '../../components/departments/Departments';
import Card from '../services/Card';

const Home = () => {
  return (
    <>
      <div className='mb-10 overflow-hidden'>
        <VideoPlayer />
      </div>
      <ChooseUs />
      <Values />
      <Team />
      <div className='gap-4 grid dark:bg-slate-800 p-4 md:grid-rows-[1fr_1fr] lg:grid-rows-1 md:grid-cols-[1fr_1fr]  lg:grid-cols-[1fr_1fr_1fr] xl:grid-cols-[1fr_1fr_1fr_1fr] bg-slate-600 my-4  '>
        <DcountUp Icon={BsFillPeopleFill} end={20} title='Doctors' bgcolor='bg-red-600' />
        <DcountUp Icon={FaAward} end={30} title='Awards' bgcolor='bg-purple-600' />
        <DcountUp Icon={ImLab} end={5} title='Laboratory' bgcolor='bg-yellow-600' />
        <DcountUp Icon={FcDepartment} end={10} title='Departments' bgcolor='bg-blue-600' />
      </div>
      <Gallery />
      <div>
        <h1 className='text-4xl dark:text-slate-200 text-gray-500 font-serif font-extrabold text-center p-5'>
          Services
        </h1>
        <div className='grid place-items-center'>
          <hr className='w-20  p-[.8px] bg-cyan-400' />
        </div>

        <div>
          <div>
            <p className='text-lg dark:text-slate-400 text-gray-600 font-serif p-4 mb-5 first-letter:text-3xl first-letter:text-cyan-500'>
              Gamo Hospital provides patients with access to the finest and the most advanced care
              in the region across a wide range of services in all medical specialties. Integrating
              the power of the human touch with the efficacy of the technology, every member of the
              team is trained to understand the emotional needs of the patient and his/her family
              and make empathy a part of their core delivery parameters.{' '}
            </p>
            <div className='grid md:grid-cols-[1fr_1fr] xl:grid-cols-[1fr_1fr_1fr] gap-4 p-4'>
              <Card
                Icon={BiRocket}
                heading='General Surgery'
                para='The General Surgery unit at Gamo Hospital is a pillar of surgical excellence, offering comprehensive and advanced surgical interventions for a wide range of conditions.'
              />
              <Card
                Icon={BiBraille}
                heading='Pediatrics & Child Care'
                para='The Pediatrics & Child Care unit at Gamo Hospital is a beacon of hope for children and families, providing exceptional medical care and nurturing support.'
              />
              <Card
                Icon={BiAnchor}
                heading='Accident and Emergency'
                para='The Accident and Emergency unit at Gamo Hospital is a lifeline in times of crisis, delivering urgent and efficient medical care when it matters most. '
              />
              <Card
                Icon={GiMedicines}
                heading='Internal/Family medicine'
                para='The Internal/Family Medicine unit at Gamo Hospital is dedicated to providing holistic and personalized care for individuals of all ages, focusing on preventive medicine and managing chronic conditions.'
              />
              <Card
                Icon={BiRupee}
                heading='Dialysis unit'
                para='The Dialysis unit at Gamo Hospital is a lifeline for patients with kidney disease, offering state-of-the-art facilities and specialized care to ensure optimal dialysis treatment.'
              />
              <Card
                Icon={FaAmbulance}
                heading='Ambulance Services'
                para='The Ambulance Services at Gamo Hospital provide rapid response and critical care transportation, ensuring timely and efficient transfer of patients in emergency situations.'
              />
            </div>
          </div>
        </div>
      </div>
      <Departments />

      <Testimonia />
      <div className='relative text-right h-96 w-full'>
        <div className='overflow-hidden bg-none h-full w-full '>
          <iframe
            width='100%'
            title='map'
            height='100%'
            id='gmap_canvas'
            src='https://maps.google.com/maps?q=gamo hospital&t=&z=10&ie=UTF8&iwloc=&output=embed'
          ></iframe>

          <br />
        </div>
      </div>
    </>
  );
};

export default Home;
