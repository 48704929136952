import React from 'react';
import CountUp from 'react-countup';

const DcountUp = ({ end, bgcolor, Icon, title }) => {
  return (
    <div
      className={` ${bgcolor} p-5  shadow-lg text-3xl text-center font-serif font-extrabold text-gray-50 mt-5 0md:mt-2 relative rounded-md drop-shadow-2xl hover:-translate-y-2 duration-200 cursor-pointer`}
    >
      {Icon && (
        <Icon className='absolute top-0 left-1/2 translate-x-[-50%] translate-y-[-50%]  text-swatch_6 bg-swatch_4 p-3 rounded-full cursor-pointer hover:bg-gray-300 text-5xl' />
      )}

      <h3>{title}</h3>
      {end && <CountUp end={end} duration={3} scrollSpyDelay={2} enableScrollSpy />}
    </div>
  );
};

export default DcountUp;
