import React from 'react';
import Card from '../services/Card';

import { BiBraille, BiRupee, BiRocket, BiAnchor } from 'react-icons/bi';
import { FaAmbulance } from 'react-icons/fa';
import { GiMedicines } from 'react-icons/gi';
import { GrRestroomWomen } from 'react-icons/gr';

const Services = () => {
  return (
    <div>
      <div>
        {/* <h1 className='text-4xl  p-6 mb-3 font-bold text-center text-cyan-600 font-serif underline underline-offset-[12px] '>
          Our Services
        </h1> */}
        <p className='text-lg text-gray-600 font-serif dark:text-slate-300 p-4 mb-5 first-letter:text-3xl first-letter:text-cyan-500'>
          Gamo Hospital provides patients with access to the finest and the most advanced care in
          the region across a wide range of services in all medical specialties. Integrating the
          power of the human touch with the efficacy of the technology, every member of the team is
          trained to understand the emotional needs of the patient and his/her family and make
          empathy a part of their core delivery parameters.{' '}
        </p>
        <div className='grid md:grid-cols-[1fr_1fr] xl:grid-cols-[1fr_1fr_1fr] gap-4 p-4'>
          <Card
            Icon={BiRocket}
            heading='General Surgery'
            para='The General Surgery unit at Gamo Hospital is a pillar of surgical excellence, offering comprehensive and advanced surgical interventions for a wide range of conditions. With a team of highly skilled surgeons and state-of-the-art operating theaters, they provide exceptional care, ensuring optimal outcomes and swift recoveries for their patients.'
          />
          <Card
            Icon={BiBraille}
            heading='Pediatrics & Child Care'
            para='The Pediatrics & Child Care unit at Gamo Hospital is a beacon of hope for children and families, providing exceptional medical care and nurturing support. With a dedicated team of specialized doctors and a child-friendly environment, they prioritize the well-being and development of every child, ensuring a compassionate and safe experience.'
          />
          <Card
            Icon={BiAnchor}
            heading='Accident and Emergency'
            para='
            The Accident and Emergency unit at Gamo Hospital is a lifeline in times of crisis, delivering urgent and efficient medical care when it matters most. With skilled emergency physicians and cutting-edge resources, they provide swift and comprehensive treatment, saving lives and offering reassurance to patients and their loved ones.'
          />
          <Card
            Icon={GiMedicines}
            heading='Internal/Family medicine'
            para='
             The Internal/Family Medicine unit at Gamo Hospital is dedicated to providing holistic and personalized care for individuals of all ages, focusing on preventive medicine and managing chronic conditions. With compassionate and experienced physicians, they foster long-term relationships with patients, emphasizing overall well-being and promoting a healthy lifestyle for the entire family.'
          />
          <Card
            Icon={BiRupee}
            heading='Dialysis unit'
            para='
The Dialysis unit at Gamo Hospital is a lifeline for patients with kidney disease, offering state-of-the-art facilities and specialized care to ensure optimal dialysis treatment. With a team of skilled nephrologists and dedicated staff, they provide a comfortable and supportive environment, enabling patients to manage their condition and improve their quality of life.'
          />
          <Card
            Icon={FaAmbulance}
            heading='Ambulance Services'
            para='The Ambulance Services at Gamo Hospital provide rapid response and critical care transportation, ensuring timely and efficient transfer of patients in emergency situations. With highly trained paramedics and well-equipped ambulances, they prioritize patient safety and swift medical intervention, serving as a crucial link in the healthcare chain.'
          />
          <Card
            Icon={GrRestroomWomen}
            heading='Obstetrics & Gynaecology'
            para="Gamo Hospital's Obstetrics & Gynecology Department is a comprehensive center of excellence for women's reproductive health, providing a wide range of specialized services with utmost care and expertise. Led by a dedicated team of experienced obstetricians and gynecologists, the department offers comprehensive prenatal care, personalized birth plans, and advanced obstetric interventions to ensure the well-being of both mother and baby. In addition, the department addresses various gynecological concerns and offers screenings, diagnostics, and treatments for reproductive disorders, infertility, and gynecological cancers. With state-of-the-art facilities, advanced technology, and a compassionate approach, Gamo Hospital's Obstetrics & Gynecology Department strives to empower women, promoting their reproductive health, and enhancing their overall well-being throughout every stage of life."
          />
        </div>
        <div className='md: flex 0md:block'>
          <ul className='list-disc p-6 font-medium text-xl space-y-3 dark:text-slate-300 text-gray-700 ml-4 marker:text-sky-400'>
            <li className='p-2'>Laboratory services</li>
            <li className='p-2'>X-ray and Ultrasound scan</li>
            <li className='p-2'>ECG</li>
            <li className='p-2'>Intensive care unit </li>
            <li className='p-2'>Dialysis unit</li>
            <li className='p-2'>Accident and Emergency</li>
            <li className='p-2'>Medical Fitness Examination</li>
            <li className='p-2'>Ambulance Services</li>
            <li className='p-2'>Family/Private/Corporate Medical Insurance</li>
          </ul>
          <ul className='list-disc p-6 font-medium dark:text-slate-300 text-xl space-y-3 text-gray-700 ml-4 marker:text-sky-400'>
            <li className='p-2'>General Outpatient Clinics</li>
            <li className='p-2'>Specialist Consultations</li>
            <li className='p-2'>General Surgery</li>
            <li className='p-2'>Internal/Family medicine</li>
            <li className='p-2'>Pediatrics & Child Care</li>
            <li className='p-2'>Neonatal care </li>
            <li className='p-2'>O&G and Specialised and Special Maternal Care</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;
