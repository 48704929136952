import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Appointment = () => {
  const EMAILJS_SERVICE_ID = 'service_vgcz43t';
  const EMAILJS_Public_Key = 'fSsVILGksrGTRcEdH';
  const EMAILJS_template = 'template_7ltdvm8';
  // deeventguy@gmail.com

  const [form, setForm] = useState({
    Name: '',
    Email: '',
    Phone: '',
    Date: '',
    Time: '',
    Message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(EMAILJS_SERVICE_ID, EMAILJS_template, form, EMAILJS_Public_Key)
      .then((result) => {
        e.target.reset();
        toast.success('🦄 Message Sent, We will get back to you shortly', result.text, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .catch((err) => {
        alert('An error occurred, Please try again', err.text);
        e.target.reset();
        toast.error('An error occurred, Please try again', err.text, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  return (
    <div>
      <div className='mt-2 '>
        <form
          className='  px-4 w-[90%] dark:bg-slate-800 dark:text-white text-gray-400 bg-white grid mx-auto gap-2  font-serif font-semibold text-base  md:grid-cols-[1fr_1fr_1fr] shadow-lg dark:shadow-none'
          onSubmit={handleSubmit}
        >
          <input
            type='text'
            required
            className='block px-4 py-2  border-[1px] border-gray-400 w-full outline-none rounded-lg caret-blue-400 text-gray-400'
            placeholder='Your Name'
            name='Name'
            onChange={(e) => setForm({ ...form, Name: e.target.value })}
          />
          <input
            type='email'
            required
            className='block px-4 py-2 border-[1px] border-gray-400 w-full outline-none rounded-lg caret-blue-400 text-gray-400 '
            placeholder='Your Email'
            name='Email'
            onChange={(e) => setForm({ ...form, Email: e.target.value })}
          />
          <input
            type='number'
            required
            className='block px-4 py-2 border-[1px] border-gray-400 w-full outline-none rounded-lg caret-blue-400 text-gray-400 '
            placeholder='Your Phone'
            name='Phone'
            onChange={(e) => setForm({ ...form, Phone: e.target.value })}
          />
          <input
            type='date'
            required
            className='block px-4 py-2 cursor-pointer border-[1px] border-gray-400 w-full outline-none rounded-lg caret-blue-400 text-gray-400'
            placeholder='Appointment Date'
            name='Date'
            onChange={(e) => setForm({ ...form, Date: e.target.value })}
          />
          <input
            type='time'
            required
            className='block px-4 py-2 cursor-pointer border-[1px] border-gray-400 w-full outline-none rounded-lg caret-blue-400 text-gray-400'
            placeholder='Appointment time'
            name='Time'
            onChange={(e) => {
              const [hours] = e.target.value.split(':');

              if (parseInt(hours, 10) >= 12) {
                setForm({ ...form, Time: e.target.value + 'PM' });
              } else {
                setForm({ ...form, Time: e.target.value + 'AM' });
              }
            }}
          />
          <textarea
            placeholder='Message'
            className='px-4 py-2 text-gray-400'
            name='Message'
            onChange={(e) => setForm({ ...form, Message: e.target.value })}
          />

          <input
            type='submit'
            className='block px-4 py-2 cursor-pointer border-none border-gray-400 w-full outline-none rounded-lg bg-green-400 text-white hover:bg-green-500'
          />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Appointment;
