import React, { useState } from 'react';
import { ImLocation2 } from 'react-icons/im';
import { BiPhoneCall } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const EMAILJS_SERVICE_ID = 'service_vgcz43t';
  const EMAILJS_Public_Key = 'fSsVILGksrGTRcEdH';
  const EMAILJS_template = 'template_3y80qym';
  // deeventguy@gmail.com

  const [form, setForm] = useState({
    Name: '',
    Email: '',
    Subject: '',
    Message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(EMAILJS_SERVICE_ID, EMAILJS_template, form, EMAILJS_Public_Key)
      .then((result) => {
        e.target.reset();
        const notify = () =>
          toast.success('🦄 Message Sent, We will get back to you shortly', result.text, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        notify();
      })
      .catch((err) => {
        toast.error('An error occurred, Please try again', err.text, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        e.target.reset();
      });
  };

  return (
    <div className='mt-5 '>
      <div className='md:flex gap-4 mb-3 justify-center p-2 text-center'>
        <div className=' flex flex-col'>
          <div className='px-6'>
            <div
              className='shadow-2xl bg-white rounded-md p-4 dark:mix-blend-soft-light'
              data-aos='fade-right'
              data-aos-duration='2500'
            >
              <div className='grid place-items-center'>
                <ImLocation2 className='icon' />
              </div>

              <h2 className='text-3xl py-2 font-serif font-semibold text-gray-500'>Our Address</h2>
              <p className='text-gray-700 text-base'>
                No 3 jilord close, off shell location road, Oyigbo, Rivers state
              </p>
            </div>
          </div>
          <div className='md:flex 0md:space-y-6 gap-4 p-6 '>
            <div
              className='bg-white px-6 py-4 rounded-md shadow-2xl md:w-1/2 dark:mix-blend-soft-light '
              data-aos='fade-up'
              data-aos-duration='2000'
            >
              <div className='grid place-items-center'>
                <BiPhoneCall className='icon ' />
              </div>

              <h2 className='text-3xl py-2 font-serif font-semibold text-gray-500'>Call Us</h2>
              <p className='text-gray-700 text-base'>+2348035570679</p>
              <p className='text-gray-700 text-base'>+2348150372504</p>
            </div>
            <div
              className='bg-white px-6 py-4 rounded-md shadow-2xl xl:w-1/2 dark:mix-blend-soft-light'
              data-aos='fade-up'
              data-aos-duration='3000'
            >
              <div className='grid place-items-center'>
                <MdEmail className='icon' />
              </div>

              <h2 className='text-3xl py-2 font-serif font-semibold text-gray-500'>Email Us</h2>
              <p className='text-gray-700 text-base'>Info@gamohospital.com</p>
              <p className='text-gray-700 text-base'>contact@gamohospital.com</p>
            </div>
          </div>
        </div>
        <form
          className='shadow-2xl p-6  bg-white  0md:mt-4 rounded-md dark:mix-blend-soft-light'
          data-aos='fade-right'
          data-aos-duration='2500'
          onSubmit={handleSubmit}
        >
          <div className='md:flex gap-4'>
            <input
              type='text'
              className='px-4 py-2 focus:outline-green-600'
              placeholder='Your Name'
              required
              onChange={(e) => setForm({ ...form, Name: e.target.value })}
            />
            <input
              type='email'
              className='px-4 py-2  focus:outline-green-600'
              placeholder='Your Email'
              required
              onChange={(e) => setForm({ ...form, Email: e.target.value })}
            />
          </div>
          <input
            type='text'
            className='px-4 py-2  focus:outline-green-600'
            placeholder='subject'
            required
            onChange={(e) => setForm({ ...form, Subject: e.target.value })}
          />
          <textarea
            rows={5}
            placeholder='Message'
            className='px-4 py-2  focus:outline-green-600'
            required
            onChange={(e) => setForm({ ...form, Message: e.target.value })}
          />
          <div className='grid place-items-center'>
            <input
              type='submit'
              className='bg-green-400 w-fit cursor-pointer px-6 py-2 text-white font-semibold text-lg rounded-md hover:bg-green-700 outline-none border-none'
            />
          </div>
        </form>
      </div>
      <div className='relative text-right h-96 w-full'>
        <div className='overflow-hidden bg-none h-full w-full '>
          <iframe
            width='100%'
            title='map'
            height='100%'
            id='gmap_canvas'
            src='https://maps.google.com/maps?q=gamo hospital&t=&z=10&ie=UTF8&iwloc=&output=embed'
          ></iframe>

          <br />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
