import { useEffect } from 'react';
import Nav from './components/nav/Nav';

import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home/Home';
import Services from './pages/services/Services';
import Footer from './components/footer/Footer';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Appointment from './pages/appointment/Appointment';

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <main className='bg-gray-50 min-h-screen overflow-hidden dark:bg-slate-800 '>
      <div className='max-w-[1800px] mx-auto  '>
        <Nav />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='services' element={<Services />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='appointment' element={<Appointment />} />
        </Routes>
        <Footer />
      </div>
    </main>
  );
};

export default App;
