import React from 'react';

const About = () => {
  return (
    <div className='p-4 '>
      <div className='md:grid-cols-[1fr_1fr] grid gap-4 my-5 dark:text-slate-300'>
        <img src='m.svg' alt='shit' data-aos='fade-right' data-aos-duration='1000' />
        <div className='space-y-4 ' data-aos='fade-left' data-aos-duration='2000'>
          <h1 className='text-4xl py-4 font-bold dark:text-slate-200 text-gray-600 font-serif'>
            About Us
          </h1>
          <p className='text-lg font-serif'>
            Gamo Hospital is a 26 bedded super hospital, the flagship hospital of Gamo Hospital
            happens to be the most preferred global healthcare destination. Located 53 minutes from
            Port Harcourt International Airport in Rivers State. Gamo Hospital is a state-of-the-art
            hospital with ultra-modern technology and Internationally acclaimed Doctors. It provides
            world-class health services across all major Super Specialties’ delivering preventive,
            diagnostic, therapeutic, rehabilitative, palliative and supportive services all under
            one roof.
          </p>
          <p className='text-lg font-serif'>
            Driven by the motto of unto God with Excellence to provide affordable and accessible
            healthcare services, the Institute offers treatment to patients from over 50 countries.
            Having been ranked “Best Hospital” and being reviewed consistently by patients for
            excellent medical service delivery, Gamo Hospital has been adjudged as one of the best
            hospitals in Rivers State by the Rivers State Ministry of Health. Through its quality
            consistent efforts for years altogether, Gamo is an emerging leader and delivers
            patient-focused healthcare touching and enriching lives ensuring the best patient
            experience possible through its doctors, nurses, and all other members committed to
            global excellence in patient care.
          </p>
          <p className='text-lg font-serif'>
            The Nigerian healthcare industry has left no stone unturned in its endeavour to deliver
            the best to the world. And at the forefront of this noble crusade is Gamo Hospital,
            built strongly on the values that Nigeria has nurtured and practiced
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
