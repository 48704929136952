import { AiOutlineArrowUp } from 'react-icons/ai';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className='dark:bg-slate-800 dark:text-slate-300 pt-4 mb-2 font-serif border-t-[0.5px] shadow bg-gray-100 mt-2  text-gray-700'>
      <div className='grid lg:grid-cols-[1fr_1fr_1fr]'>
        <div className=' p-4'>
          <h3 className='font-semibold text-xl mb-3'>Gamo Hospital</h3>
          <p className='text-base'>
            No 3 jilord close, off shell location road, Oyigbo, Rivers state
          </p>
          <p className='text-base'>Phone:+234 803 557 0679</p>
          <p className='text-base'>Email1: info@gamohospital.com</p>
          <p className='text-base'>Email2: contact@gamohospital.com</p>
          <div className='flex mt-2 gap-x-2'>
            <FaFacebookF className='text-3xl rounded-sm cursor-pointer hover:bg-cyan-300 bg-cyan-400 text-white p-1' />
            <FaTwitter className='text-3xl rounded-sm cursor-pointer hover:bg-cyan-300 bg-cyan-400 text-white p-1' />
            <FaInstagram className='text-3xl rounded-sm cursor-pointer hover:bg-cyan-300 bg-cyan-400 text-white p-1' />
            <FaLinkedin className='text-3xl rounded-sm cursor-pointer hover:bg-cyan-300 bg-cyan-400 text-white p-1' />
          </div>
        </div>
        <div className=' p-4'>
          <h3 className='font-semibold text-xl mb-3'>Useful Links</h3>
          <Link to='/' className='block hover:text-cyan-300  mb-2  font-semibold w-1/2'>
            Home
          </Link>
          <Link to='/about' className='block hover:text-cyan-300  mb-2  font-semibold w-1/2'>
            About
          </Link>
          <Link to='/services' className='block hover:text-cyan-300  mb-2  font-semibold w-1/2'>
            Services
          </Link>
          <Link to='/contact' className='block hover:text-cyan-300  mb-2  font-semibold w-1/2'>
            Contact Us
          </Link>
          <Link to='#' className='block hover:text-cyan-300  mb-2  font-semibold w-1/2'>
            Departments
          </Link>
        </div>
        <div className=' p-4'>
          <h3 className='font-semibold text-xl mb-3'>Our Services</h3>
          <p className='mb-2 font-semibold'>Ambulance</p>
          <p className='mb-2 font-semibold'>Laboratory</p>
          <p className='mb-2 font-semibold'>X-ray</p>
          <p className='mb-2 font-semibold'>Scan</p>
          <p className='mb-2 font-semibold'>Neonatal care </p>
          <Link
            to='/services'
            className='bg-cyan-300 font-semibold px-3 py-2 rounded-sm text-white hover:bg-cyan-700 transition'
          >
            see more
          </Link>
        </div>
      </div>
      <div className='bg-cyan-900 py-3 text-white relative dark:bg-slate-900 dark:text-slate-300'>
        <p className='text-center text-sm'>
          {' '}
          &copy; Copyright <span className='font-extrabold text-gray-100'>gamohospital</span> {''}
          {new Date().getFullYear()}. All Rights Reserved
        </p>
        <p className='text-center text-sm font-[cursive]'>
          Designed by nedbleezy<span className='text-red-600'>Tech</span>{' '}
        </p>

        <AiOutlineArrowUp
          className='text-4xl bg-cyan-400 cursor-pointer  text-white shadow-xl rounded-sm fixed bottom-5 left-[85%]   p-1 animate-pulse'
          onClick={() => (document.documentElement.scrollTop = 0)}
        />
      </div>
    </div>
  );
};

export default Footer;
