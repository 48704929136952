import React from 'react';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  {
    original: 'g1.jpg',
    thumbnail: 'g1.jpg',
  },
  {
    original: 'g2.jpg',
    thumbnail: 'g2.jpg',
  },
  {
    original: 'g3.jpg',
    thumbnail: 'g3.jpg',
  },
  {
    original: 'g4.jpg',
    thumbnail: 'g4.jpg',
  },
  {
    original: 'g5.jpg',
    thumbnail: 'g5.jpg',
  },
  {
    original: 'https://cdn.pixabay.com/photo/2017/10/04/09/56/laboratory-2815641_640.jpg',
    thumbnail: 'https://cdn.pixabay.com/photo/2017/10/04/09/56/laboratory-2815641_640.jpg',
  },
  {
    original:
      'https://media.istockphoto.com/id/512278456/photo/group-of-doctors-at-the-hospital.jpg?s=612x612&w=0&k=20&c=EPPHeKuq0YabUC-QCWlAOhTfIZAAPtrwQ96V_Wp0oKY=',
    thumbnail:
      'https://media.istockphoto.com/id/512278456/photo/group-of-doctors-at-the-hospital.jpg?s=612x612&w=0&k=20&c=EPPHeKuq0YabUC-QCWlAOhTfIZAAPtrwQ96V_Wp0oKY=',
  },
  {
    original: 'https://cdn.pixabay.com/photo/2016/11/14/03/05/surgery-1822458_640.jpg',
    thumbnail: 'https://cdn.pixabay.com/photo/2016/11/14/03/05/surgery-1822458_640.jpg',
  },
  {
    original: 'https://cdn.pixabay.com/photo/2017/02/01/13/52/analysis-2030261_640.jpg',
    thumbnail: 'https://cdn.pixabay.com/photo/2017/02/01/13/52/analysis-2030261_640.jpg',
  },
  {
    original:
      'https://media.istockphoto.com/id/467655519/photo/group-of-happy-multiracial-doctors.jpg?s=612x612&w=0&k=20&c=iswEBBBbSJVgBMO-cfx_GMgUgm4HAhw513LQIEy0GXE=',
    thumbnail:
      'https://media.istockphoto.com/id/467655519/photo/group-of-happy-multiracial-doctors.jpg?s=612x612&w=0&k=20&c=iswEBBBbSJVgBMO-cfx_GMgUgm4HAhw513LQIEy0GXE=',
  },
];

const Gallery = () => {
  return (
    <div className='mt-10'>
      <h3 className='text-3xl text-center dark:text-slate-200 text-gray-600 mb-2 font-serif font-semibold'>
        GALLERY
      </h3>
      <div className='grid place-items-center'>
        <hr className='p-[1px] bg-cyan-400 w-20' />
      </div>

      <div className='my-4 '>
        <ImageGallery
          items={images}
          autoPlay={true}
          slideInterval={5000}
          renderLeftNav={() => null}
          renderRightNav={() => null}
          showPlayButton={false}
        />
      </div>
    </div>
  );
};

export default Gallery;
