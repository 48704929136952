import React from 'react';

import { FaDraftingCompass } from 'react-icons/fa';

const Values = () => {
  return (
    <div className='p-4 bg-swatch_6/80 dark:bg-slate-800'>
      <h3 className='text-4xl text-center dark:text-slate-200 mb-4 font-serif font-bold text-gray-600'>
        Our Core Values
      </h3>
      <div
        className='flex 0md:justify-between  dark:text-slate-300 md:justify-evenly gap-2 text-lg text-white font-serif'
        data-aos='zoom-in'
      >
        <div>
          <div className='flex items-center space-y-3'>
            <FaDraftingCompass className='mr-2' />
            <span>Compassion</span>
          </div>
          <div className='flex items-center'>
            <FaDraftingCompass className='mr-2' />
            <span>Dedication</span>
          </div>
          <div className='flex items-center'>
            <FaDraftingCompass className='mr-2' />
            <span>Integrity</span>
          </div>
          <div className='flex items-center'>
            <FaDraftingCompass className='mr-2' />
            <span>Teamwork</span>
          </div>
          <div className='flex items-center'>
            <FaDraftingCompass className='mr-2' />
            <span>Excellence </span>
          </div>
        </div>
        <div>
          <div className='flex items-center space-y-3'>
            <FaDraftingCompass className='mr-2' />
            <span>Transparency </span>
          </div>
          <div className='flex items-center'>
            <FaDraftingCompass className='mr-2' />
            <span>Godliness</span>
          </div>
          <div className='flex items-center'>
            <FaDraftingCompass className='mr-2' />
            <span>Partnership</span>
          </div>
          <div className='flex items-center'>
            <FaDraftingCompass className='mr-2' />
            <span>Patient centeredness</span>
          </div>
          <div className='flex items-center'>
            <FaDraftingCompass className='mr-2' />
            <span>Partnership</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
