import React, { useEffect, useRef } from 'react';

const VideoPlayer = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleVideoEnd = () => {
      videoElement.currentTime = 0; // Restart the video
      videoElement.play(); // Play the video again
    };

    const playVideo = () => {
      videoElement.play(); // Play the video
    };

    videoElement.addEventListener('ended', handleVideoEnd);

    if (document.readyState === 'complete') {
      // If the document has already loaded
      playVideo();
    } else {
      // Wait for the document to finish loading
      document.addEventListener('DOMContentLoaded', playVideo);
    }

    return () => {
      videoElement.removeEventListener('ended', handleVideoEnd);
      document.removeEventListener('DOMContentLoaded', playVideo);
    };
  }, []);

  return (
    <div>
      <video ref={videoRef} src='WelcomeMessage.mp4' autoPlay muted />
    </div>
  );
};

export default VideoPlayer;
