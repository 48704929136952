import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
const Card = ({ img, title, quote, name }) => {
  return (
    <div className='  rounded-lg mx-3 '>
      <div className='bg-blue-50 relative p-5 rounded-lg dark:bg-cyan-100 '>
        <p className='dark:text-gray-600 text-lg p-2 text-gray-400 font-extralight italic font-serif'>
          <FaQuoteLeft className='inline text-2xl mr-2 text-blue-200' /> {quote}{' '}
          <FaQuoteRight className='inline text-2xl ml-2 text-blue-200' />
        </p>

        <img
          src={img}
          alt='me'
          className='dark:ring-cyan-600 rounded-full w-20 h-20 absolute -bottom-15 left-10 ring-4 shadow-lg ring-white block object-cover object-top bg-slate-600'
        />
      </div>
      <div className='p-4 mt-10 font-serif '>
        <p className='mt-1 pl-8 dark:text-slate-300 text-xl font-semibold text-gray-700'>{name}</p>
        <p className='pl-8 font-semibold text-lg text-gray-500'>{title}</p>
      </div>
    </div>
  );
};

export default Card;
